import { useLocation } from '@reach/router';
import { graphql } from 'gatsby';
import Image from 'gatsby-image';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import moment from 'moment';
import qs from 'qs';
import React, { useEffect, useState } from 'react';
import SEO from '../components/SEO';
import { ANICOLOR } from '../theme/palette';

const Blog = ({ data }) => {
  const location = useLocation();
  const [totalItm, setTotalItm] = useState(6);
  const [post, setPost] = useState([]);
  const [itmPort, setItmPort] = useState(0);

  const query = location.search ? qs.parse(location.search.substring(1)) : {};
  const handleScroll = e => {
    const page = document.getElementById('news-section');

    let pageHeight = 300;
    if (typeof page !== 'undefined' && page !== null) {
      pageHeight = page.offsetHeight;
    }

    const itmLoad = document.getElementsByClassName('item-news').length;

    if (e.target.documentElement.scrollTop >= pageHeight - 550) {
      if (itmLoad < data.posts.nodes.length) {
        const nt = totalItm + 6;
        setTotalItm(nt);
      }

      setItmPort(itmLoad);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    if (data) {
      if (Object.keys(query).length !== 0) {
        setPost(
          data.posts.nodes
            .filter(x => x.categories.value._id === query.cat)
            .slice(0, totalItm)
        );
      } else {
        setPost(data.posts.nodes.slice(0, totalItm));
      }
    }
  }, [data, query, totalItm, itmPort]);

  return (
    <>
      <SEO title="Blog & Artikel" />
      <section
        id="news-section"
        className="news-container pt-40 pb-8 px-2 sm:px-16 2xl:px-24  min-h-screen"
      >
        <h4 className="title-section">News</h4>
        <h3 className="subtitle-section">
          The result of
          <span className="px-3 text-transparent bg-clip-text bg-gradient-to-br from-orange-600 to-yellow-500">
            our work
          </span>
          with colleagues
        </h3>
        <div className="md:grid grid-cols-3 gap-8 mt-10 lg:mt-16 mb-5 w-full  lg:w-4/5 mx-auto">
          {post.map((d, idx) => {
            return (
              <div className="news item-news flex flex-col">
                <div className="img-wrapper">
                  <Image
                    fixed={d.main_image.value.childImageSharp.fixed}
                    className="img-porto !w-full rounded-xl drop-shadow-lg"
                  />
                </div>
                <div className="cat-wrapper">
                  <a href={d ? `/blog?cat=${d.categories.value._id}` : '#'}>
                    {d ? d.categories.value.display : 'No Category'}
                  </a>
                  <span class="text-right">
                    {moment(d.date.value).format('MMM DD, YYYY')}
                  </span>
                </div>
                <h3 className="text-lg font-bold text-gray-700 mt-4 mb-6">
                  <AniLink
                    paintDrip
                    hex={ANICOLOR}
                    to={`/blog/${d.slug.value || generateSlug(d.title.value)}-${
                      d.cockpitId
                    }`}
                  >
                    {d.title.value}
                  </AniLink>
                </h3>
                <div class="flex flex-col justify-end gap-y-4 flex-auto">
                  <p className="text-gray-600">{d.description.value}</p>
                  <div>
                    <AniLink
                      paintDrip
                      hex={ANICOLOR}
                      to={`/blog/${
                        d.slug.value || generateSlug(d.title.value)
                      }-${d.cockpitId}`}
                      className="my-5 pb-1 inline-block border-b border-gray-400 text-gray-400 hover:text-gray-600 hover:border-gray-600"
                    >
                      Continue Reading
                    </AniLink>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        {itmPort < data.posts.nodes.length ? (
          <div className="btn-wrapper text-center text-gray-600 mt-8">
            <a href="/blog/" className="hover:text-gray-800">
              Load More...
            </a>
          </div>
        ) : null}
      </section>
    </>
  );
};

export const query = graphql`
  query {
    posts: allCockpitPosts(
      filter: { active: { value: { eq: true } } }
      sort: { fields: date___value, order: DESC }
    ) {
      nodes {
        cockpitId
        cockpitBy
        title {
          value
        }
        slug {
          value
        }
        categories {
          value {
            display
            _id
          }
        }
        date {
          value
        }
        description {
          value
        }
        main_image {
          value {
            childImageSharp {
              fixed(width: 480, toFormat: WEBP) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }
        }
      }
    }
  }
`;

export default Blog;
